<script lang="ts">
  import VideoBackground from "../common/VideoBackground.svelte";
</script>

<div class="mx-auto w-full h-full">
  <VideoBackground
    poster="https://modal-cdn.com/tmpm9makfxp_73c169ef.webp"
    objectFit="fill"
  >
    <source
      src="https://modal-cdn.com/landscape-vids/Modal_Bars-hevc-safari.mp4"
      type="video/mp4"
    />
    <source
      src="https://modal-cdn.com/landscape-vids/Modal_Bars-vp9-chrome.webm"
      type="video/webm"
    />
  </VideoBackground>
</div>
