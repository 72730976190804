<script lang="ts">
  let perHour = false;
  let hourElement: HTMLElement;
  let secondElement: HTMLElement;
  let slider: HTMLElement;

  $: activeElement = perHour ? hourElement : secondElement;

  $: if (slider && activeElement) {
    const { offsetLeft, offsetWidth } = activeElement;
    const offsetRight = slider.offsetWidth - (offsetLeft + offsetWidth);
    slider.style.clipPath = `inset(8px ${offsetRight}px 8px ${offsetLeft}px round 999px)`;
  }

  const gpuCosts = [
    {
      name: "Nvidia H100",
      costPerHour: 4.56,
    },
    {
      name: "Nvidia A100, 80 GB",
      costPerHour: 3.4,
    },
    {
      name: "Nvidia A100, 40 GB",
      costPerHour: 2.78,
    },
    {
      name: "Nvidia A10G",
      costPerHour: 1.1,
    },
    {
      name: "Nvidia L4",
      costPerHour: 0.8,
    },
    {
      name: "Nvidia T4",
      costPerHour: 0.59,
    },
  ];

  const cpuCostPerHour = 0.135;
  const memoryCostPerHour = 0.024;
</script>

<div>
  <div class="flex gap-3 justify-between items-center">
    <h3 class="marketing-h4">Compute costs</h3>
    <div class="flex">
      <button
        on:click={() => (perHour = !perHour)}
        class="relative flex items-center justify-between p-2 rounded-full cursor-pointer bg-[#323835]"
      >
        <!-- Sliding background indicator -->
        <div
          bind:this={slider}
          class="absolute inset-0 flex items-center justify-between p-2 bg-primary ease-out duration-200"
          style="clip-path: inset(8px 7px 8px 81px round 999px); transition-property: clip-path;"
        >
          <div class="slider-option text-black">Per hour</div>
          <div class="slider-option text-black">Per second</div>
        </div>

        <div bind:this={hourElement} class="slider-option text-light-green/60">
          Per hour
        </div>
        <div
          bind:this={secondElement}
          class="slider-option text-light-green/60"
        >
          Per second
        </div>
      </button>
    </div>
  </div>

  <hr class="mt-4 mb-5 border-light-green/30 marketing-contained-negate-mr" />

  <p class="font-medium text-lg mb-2">GPU Tasks</p>

  <div class="space-y-2">
    {#each gpuCosts as { name, costPerHour }}
      <div class="line-item">
        <p class="text-light-green/60">{name}</p>
        {#if perHour}
          <p class="price">
            ${costPerHour.toFixed(2)}
            <span class="text-light-green/60">/ h</span>
          </p>
        {:else}
          <p class="price">
            ${(costPerHour / 3600).toFixed(6)}
            <span class="text-light-green/60">/ sec</span>
          </p>
        {/if}
      </div>
    {/each}
  </div>

  <hr class="my-5 border-light-green/30 marketing-contained-negate-mr" />

  <p class="font-medium text-lg mb-2">CPU</p>

  <div class="line-item items-start">
    <p class="shrink-0 text-light-green/60">
      Physical core<br class="inline lg:hidden" />
      {" "}(2 vCPU<span class="hidden sm:inline">{" "}equivalent</span>)
    </p>
    <div class="flex flex-col items-end">
      {#if perHour}
        <p class="price">
          ${cpuCostPerHour.toFixed(3)}
          <span class="text-light-green/60">/ core / h</span>
        </p>
      {:else}
        <p class="price">
          ${(cpuCostPerHour / 3600).toFixed(6)}
          <span class="text-light-green/60">/ core / sec</span>
        </p>
      {/if}
      <p class="mt-1 text-xs text-light-green/60 text-right">
        *minimum of 0.125 cores per container
      </p>
    </div>
  </div>

  <hr class="my-5 border-light-green/30 marketing-contained-negate-mr" />

  <div class="line-item items-end">
    <p class="font-medium text-lg">Memory</p>
    {#if perHour}
      <p class="price">
        ${memoryCostPerHour.toFixed(3)}
        <span class="text-light-green/60">/ GiB / h</span>
      </p>
    {:else}
      <p class="price">
        ${(memoryCostPerHour / 3600).toFixed(8)}
        <span class="text-light-green/60">/ GiB / sec</span>
      </p>
    {/if}
  </div>
</div>

<style lang="postcss">
  .line-item {
    @apply flex flex-row justify-between;
  }

  .price {
    @apply sm:ml-auto tabular-nums whitespace-nowrap;
  }

  .slider-option {
    @apply text-xs font-medium whitespace-nowrap px-3 py-1;
  }
</style>
